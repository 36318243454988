import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {AutocompleteArrayInput, Create, Edit, PasswordInput, SimpleForm, TextField, TextInput} from 'react-admin';

const TextArrayField = ({record, source}) => {
    const array = record[source]
    if (typeof array === 'undefined' || array === null || array.length === 0) {
        return <div/>
    } else {
        return (<>
            {array.join(', ')}
        </>)
    }
}
TextArrayField.defaultProps = {addLabel: true}

export const UserList = props => (
    <ListGuesser {...props}>
        <TextField source="id" />
        <FieldGuesser source="email"/>
        <TextArrayField source="roles">
        </TextArrayField>
    </ListGuesser>
);

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="email"/>
            <PasswordInput source="password"/>
            <AutocompleteArrayInput
                source="roles" choices={[{id: 'ROLE_ADMIN', name: 'ROLE_ADMIN'}, {
                id: 'ROLE_USER', name: 'ROLE_USER'
            },]}/>
        </SimpleForm>
    </Create>);

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="email"/>
            <PasswordInput source="password"/>
            <AutocompleteArrayInput source="roles" choices={[
                {id: 'ROLE_ADMIN', name: 'ROLE_ADMIN'},
                {id: 'ROLE_USER', name: 'ROLE_USER'},
            ]}/>
        </SimpleForm>
    </Edit>
);
