import {CreateGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import {FileField, FileInput} from "react-admin";

export const EventCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"name"}/>
        <InputGuesser source={"startDate"}/>
        <InputGuesser source={"place"}/>
        <InputGuesser source={"parentEvent"}/>
        <FileInput source="file">
            <FileField source="src" title="title"/>
        </FileInput>
    </CreateGuesser>
);

export const EventList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"name"} />
        <FieldGuesser source={"startDate"} />
        <FieldGuesser source={"place"} />
        <FieldGuesser source={"countCompany"} />
        <FieldGuesser source={"updatedAt"} />
    </ListGuesser>
);
