import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import {AutocompleteInput, FileField, FileInput, ReferenceArrayInput, SelectArrayInput} from 'react-admin';
import MyImageField from "../MyImageField";

export const PartnerList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source="name"/>
        <FieldGuesser source="eventsList"/>
        <MyImageField source="contentUrl"/>
    </ListGuesser>
);

export const PartnerCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="website"/>
        <FileInput source="file">
            <FileField source="src" title="title"/>
        </FileInput>
    </CreateGuesser>
);


export const PartnerEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="website"/>
        <FileInput source="file">
            <FileField source="src" title="title"/>
        </FileInput>
        <ReferenceArrayInput reference="events" source="events">
            <SelectArrayInput
                label="Event"
                filterToQuery={searchText => ({name: searchText})}
            >
                <AutocompleteInput optionText="name"/>
            </SelectArrayInput>
        </ReferenceArrayInput>
    </EditGuesser>
);

