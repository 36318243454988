import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import {AutocompleteInput, FileField, FileInput, ReferenceArrayInput, SelectArrayInput} from 'react-admin';
import MyImageField from "../MyImageField";

export const CompanyList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source="name"/>
        <FieldGuesser source="payed"/>
        <FieldGuesser source="ownerEmail"/>
        <FieldGuesser source="eventsList"/>
        <FieldGuesser source="categoryList"/>
        <MyImageField source="contentUrl"/>
    </ListGuesser>
);

export const CompanyCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="website"/>
        <InputGuesser source={"owner"}/>
        <FileInput source="file">
            <FileField source="src" title="title"/>
        </FileInput>
    </CreateGuesser>
);


export const CompanyEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="website"/>
        <InputGuesser source="caption"/>
        <InputGuesser source={"owner"}/>
        <FileInput source="file">
            <FileField source="src" title="title"/>
        </FileInput>
        <ReferenceArrayInput reference="events" source="events">
            <SelectArrayInput
                label="Event"
                filterToQuery={searchText => ({name: searchText})}
            >
                <AutocompleteInput optionText="name"/>
            </SelectArrayInput>
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="categories" source="categories">
            <SelectArrayInput
                label="Category"
                filterToQuery={searchText => ({name: searchText})}
            >
                <AutocompleteInput optionText="name"/>
            </SelectArrayInput>
        </ReferenceArrayInput>
    </EditGuesser>
);

