import {CreateGuesser, InputGuesser} from "@api-platform/admin";
import RichTextInput from "ra-input-rich-text";
import {FileField, FileInput} from "react-admin";

const TestimonyCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="title"/>
        <RichTextInput source="description"/>
        <FileInput source="file">
            <FileField source="src" title="title"/>
        </FileInput>
    </CreateGuesser>
);

export default TestimonyCreate;
