import {CreateGuesser, EditGuesser, InputGuesser} from "@api-platform/admin";


export const PaypalPaymentCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"item"} />
        <InputGuesser source={"status"} />
        <InputGuesser source="name" />
        <InputGuesser source={"email"} />
        <InputGuesser source={"currency"} />
        <InputGuesser source={"amount"} />
        <InputGuesser source={"paymentDate"} />
        <InputGuesser source={"user"}/>
        <InputGuesser source={"legalMention"} />
    </CreateGuesser>
);

export const PaypalPaymentEdit = props => (
    <EditGuesser {...props}>
            <InputGuesser source={"item"} />
            <InputGuesser source={"status"} />
            <InputGuesser source="name" />
            <InputGuesser source={"email"} />
            <InputGuesser source={"currency"} />
            <InputGuesser source={"amount"} />
            <InputGuesser source={"paymentDate"} />
            <InputGuesser source={"user"} />
            <InputGuesser source={"legalMention"} />
    </EditGuesser>
);
