import {HydraAdmin, ResourceGuesser} from "@api-platform/admin";
import { Redirect, Route } from "react-router-dom";
import {
    fetchHydra as baseFetchHydra,
    hydraDataProvider as baseHydraDataProvider,
    useIntrospection,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import TestimonyList from "./Guesser/TestimonyList";
import TestimonyCreate from "./Guesser/TestimonyCreate";
import {EventCreate, EventList} from "./Guesser/Event";
import authProvider from "./authProvider";
import {UserCreate, UserEdit, UserList} from "./Guesser/User";
import {CompanyCreate, CompanyEdit, CompanyList} from "./Guesser/Company";
import {CategoryEdit, CategoryList} from "./Guesser/Category";
import {PaypalPaymentCreate, PaypalPaymentEdit} from "./Guesser/Payment";
import {PartnerCreate, PartnerEdit, PartnerList} from "./Guesser/Partner";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`
} : {};

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login" />;
};

const apiDocumentationParser = async () => {
    try {
        return await parseHydraDocumentation(entrypoint, { headers: getHeaders });
    } catch (result) {
        const { api, response, status } = result;
        if (status !== 401 || !response) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");

        return {
            api,
            response,
            status,
            customRoutes: [
                <Route key="/" path="/" component={RedirectToLogin} />,
            ],
        };
    }
};

const dataProvider = baseHydraDataProvider({
    entrypoint: entrypoint,
    httpClient: fetchHydra,
    apiDocumentationParser,
});

const App = () => (
    <HydraAdmin dataProvider={dataProvider} authProvider={authProvider} entrypoint={entrypoint}>
        <ResourceGuesser name="testimonies" create={TestimonyCreate} list={TestimonyList}/>
        <ResourceGuesser name="events" list={EventList} create={EventCreate}/>
        <ResourceGuesser name="companies" list={CompanyList} create={CompanyCreate} edit={CompanyEdit}/>
        <ResourceGuesser name="users" list={UserList} create={UserCreate} edit={UserEdit} />
        <ResourceGuesser name="paypal_payments"  create={PaypalPaymentCreate} edit={PaypalPaymentEdit}/>
        <ResourceGuesser name="categories" list={CategoryList} edit={CategoryEdit}  />
        <ResourceGuesser name="partners" list={PartnerList} create={PartnerCreate} edit={PartnerEdit}  />
    </HydraAdmin>
);

export default App;
