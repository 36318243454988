import jwtDecode from "jwt-decode";
const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

export default {
    login: ({ username, password }) => {
        const request = new Request(
            entrypoint + `/login/login_check`,
            {
                method: "POST",
                body: JSON.stringify({ username: username, password }),
                headers: new Headers({ "Content-Type": "application/json" }),
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                
                return response.json();
            })
            .then(({ token, isAdmin }) => {
                if (!isAdmin) {
                    throw new Error('Admin only');
                }
                localStorage.setItem("token", token);
            });
    },
    logout: () => {
        localStorage.removeItem("token");
        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            // @ts-ignore
            if (!localStorage.getItem("token") || new Date().getTime() / 1000 > jwtDecode(localStorage.getItem("token"))?.exp) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    checkError: (err) => {
        if ([401, 403].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve(),
};
