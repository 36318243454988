import {EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import {ChipField, ReferenceArrayField, SingleFieldList} from 'react-admin';


export const CategoryList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"name"}/>
        <ReferenceArrayField label="Companies" source="companies" reference="companies">
            <SingleFieldList>
                <ChipField source="name"/>
            </SingleFieldList>
        </ReferenceArrayField>
    </ListGuesser>
);

export const CategoryEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"name"}/>
    </EditGuesser>
);

