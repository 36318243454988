import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import MyImageField from "../MyImageField";

const TestimonyList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source="title"/>
        <FieldGuesser source="description"/>
        <MyImageField source="contentUrl"/>
    </ListGuesser>
);


export default TestimonyList;
